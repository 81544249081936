import React, { useEffect, useContext } from "react";
import { telegramAuth } from "../../services/authService"
import "./Telegram.css";
import { AuthContext } from "../../context/AuthContext";

const TelegramLogin = () => {
  const { user, setUser } = useContext(AuthContext);
  useEffect(() => {
    window.onTelegramAuth = async (user) => {
      try {
        const response = await telegramAuth(user);
        setUser(response);
      }
      catch (error) {
        console.error(error);
        setUser(null);
      }
    };

    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?22";
    script.async = true;
    script.setAttribute("data-telegram-login", "con_serj_bot");
    script.setAttribute("data-size", "medium");
    script.setAttribute("data-radius", "20");
    script.setAttribute("data-request-access", "write");
    script.setAttribute("data-onauth", "onTelegramAuth(user)");
    script.setAttribute('data-lang', "ru");
    document.getElementById("telegram-login").appendChild(script);
  }, []);

  return (
    <div
      id="telegram-login"
    ></div>
  );
};

export default TelegramLogin;
