import { jwtDecode as jwt_decode } from 'jwt-decode';
import axios from "axios";

import { API_BASE_URL } from '../config';

export const telegramAuth = async (telegramResponse) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/member`, telegramResponse);
        return loginUser(response.data.access, response.data.refresh);
    } catch (error) {
        console.error("Telegram Login error", error);
        return logoutUser();
    }
};

export const telegramAppAuth = async (telegramRawLine) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/member`, { "init_data": telegramRawLine });
        return loginUser(response.data.access, response.data.refresh);
    } catch (error) {

        console.error("Telegram Login error", error);
        return logoutUser();
    }
};

export const loginUser = async (access, refresh) => {
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + access;

    const decoded = jwt_decode(access);
    const userData = {
        telegram_id: decoded.telegram_id,
    };
    return userData;

};


export const isTokenValid = (token) => {
    if (!token) return false;

    try {
        const decoded = jwt_decode(token);
        const currentTime = Date.now() / 1000;
        return decoded.exp > currentTime;
    } catch (error) {
        return false;
    }
};

export const logoutUser = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    delete axios.defaults.headers.common['Authorization'];
};

export const refreshToken = async () => {
    const refresh = localStorage.getItem('refresh_token');
    if (refresh && isTokenValid(refresh)) {
        try {
            const response = await axios.post(`${API_BASE_URL}/refresh`, { "refresh": refresh });
            localStorage.setItem('access_token', response.data.access);
            if (response.data.refresh) {
                localStorage.setItem('refresh_token', response.data.refresh);
            }
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;
            return response.data.access;
        } catch (error) {
            console.error('Ошибка при обновлении токена', error);
            throw error;
        }
    } else {
        throw new Error('Refresh токен недействителен');
    }
};

