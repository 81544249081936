import React from "react";
import { Link } from "react-router-dom";
import {
  telegramIcon,
  mailIcon,
} from "../../assets/images";
import { BsCCircle } from "react-icons/bs";
import "../../styles/components/layout/Footer.css"

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        {/* <Link to="/policy"> */}
        <p>Политика конфиденциальности</p>
        {/* </Link> */}

        {/* <Link to="/rules"> */}
        <p>Правила использования</p>
        {/* </Link> */}
        <div className="social-icons" id="contacts">
          <img src={telegramIcon} alt="telegramIcon" />
          <img src={mailIcon} alt="mailIcon" />
        </div>
      </div>

      <div>
        <p
          style={{
            fontSize: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BsCCircle style={{ margin: "3px" }} />
          2024 Conserj.ru, Все права защищены.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
