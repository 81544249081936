import React, { useState, useEffect } from 'react';
import { getMyBookings } from '../../services/serjServices';
import axios from 'axios';

const UserBooking = () => {
    const [bookings, setBookings] = useState({ upcoming_bookings: [], past_bookings: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchBookings = async () => {
        try {
            const response = await getMyBookings();
            console.log(response);
            setBookings(response);
            setLoading(false);
        } catch (err) {
            setError('Ошибка при загрузке бронирований');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBookings();
    }, []);

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const PlaceInfo = ({ place }) => (
        <div>
            <h4>{place.name}</h4>
            <p>Тип: {place.service_type}</p>
            <p>Метро: {place.metro.map((station) => station.name).join(', ')}</p>
            <p>Кухня: {place.cuisine.map((cuisine) => cuisine.name).join(', ')}</p>
        </div>
    );

    // Компонент для отображения одной карточки бронирования
    const BookingCard = ({ booking }) => (
        <div style={styles.card}>
            <h3>Бронирование #{booking.id}</h3>
            <p>Дата: {new Date(booking.booking_date).toLocaleDateString()}</p>
            <p>Количество людей: {booking.num_of_people}</p>
            <p>Статус: {booking.confirmed ? 'Подтверждено' : 'Ожидание подтверждения'}</p>
            <PlaceInfo place={booking.place} />
        </div>
    );

    return (
        <div>
            <h2>Мои бронирования</h2>

            {/* Предстоящие бронирования */}
            <h3>Предстоящие бронирования</h3>
            {bookings.upcoming_bookings && bookings.upcoming_bookings.length === 0 ? (
                <p>У вас нет предстоящих бронирований.</p>
            ) : (
                <div style={styles.grid}>
                    {bookings.upcoming_bookings.map((booking) => (
                        <BookingCard key={booking.id} booking={booking} />
                    ))}
                </div>
            )}

            {/* Прошедшие бронирования */}
            <h3>Прошедшие бронирования</h3>
            {bookings.past_bookings && bookings.past_bookings.length === 0 ? (
                <p>У вас нет прошедших бронирований.</p>
            ) : (
                <div style={styles.grid}>
                    {bookings.past_bookings.map((booking) => (
                        <BookingCard key={booking.id} booking={booking} />
                    ))}
                </div>
            )}
        </div>
    );
};

const styles = {
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '20px',
        padding: '20px',
    },
    card: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
    },
};

// Пример функции для просмотра деталей бронирования
const viewBookingDetails = (bookingId) => {
    window.location.href = `/booking/${bookingId}`;  // Перенаправляем на страницу с деталями бронирования
};

export default UserBooking;
