import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../context/AuthContext';
import { Link } from "react-router-dom";
import { logo } from "../assets/images";

import {
  TelegramLogin,
  Advantages,
  Navbar,
  Ads,
  How,
  Hero,
  Action,
  UserBooking
} from "../imports";
import "../styles/pages/Homepage.css";
import { telegramAppAuth, logoutUser } from "../services/authService"


const Homepage = () => {
  const [show, setShow] = useState(true);
  const { user, setUser } = useContext(AuthContext);

  let lastScrollY = window.scrollY;

  const handleLogout = () => {
    logoutUser();
    setUser(null);
  };

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY && window.scrollY > 0) {
      setShow(false);
    } else if (window.scrollY < lastScrollY || window.scrollY === 0) {
      setShow(true);
    }
    lastScrollY = window.scrollY;
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-web-app.js';
    script.async = true;

    script.onload = async () => {
      const appInfo = window.Telegram.WebApp;

      if (appInfo && appInfo.initData) {
        try {
          const response = await telegramAppAuth(appInfo.initData);
          setUser(response);
        }
        catch (error) {
          console.error(error);
        }
      }
    };

    document.body.appendChild(script);

    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <header className={show ? "header--show" : "header--hide"}>
        <img src={logo} alt="logo" height={35} width={30} />
        {/* <Navbar /> */}
        { user ? (<button onClick={handleLogout}>Выйти</button>) : (<TelegramLogin />)}
      </header>

      <main>
        <Hero />
        {!user ? (
          <>
            <How />
            <Advantages />
            <Action />
          </>
        ) :
          (<UserBooking />)}

      </main>
    </>
  );
};

export default Homepage;