import yaml from 'js-yaml';

export const loadTexts = async () => {
    const file = '/locales/ru.yaml';

    try {
        const response = await fetch(file);
        console.log("!!!!!!", response)
        const text = await response.text();
        return yaml.load(text);
      } catch (error) {
        console.error('Ошибка при загрузке файла:', error);
        return null;
      }
};