import React, { createContext, useState, useEffect } from 'react';
import { refreshToken, logoutUser, isTokenValid } from '../services/authService';
import {jwtDecode as jwt_decode} from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const initializeAuth = async () => {
            const accessToken = localStorage.getItem('access_token');
            if (accessToken && isTokenValid(accessToken)) {
                const userData = getUserDataFromToken(accessToken);
                setUser(userData);
            } else {
                const refresh = localStorage.getItem('refresh_token');
                if (refresh && isTokenValid(refresh)) {
                    try {
                        await refreshToken();
                        const newAccessToken = localStorage.getItem('access_token');
                        const userData = getUserDataFromToken(newAccessToken);
                        setUser(userData);
                    } catch (error) {
                        logoutUser();
                        setUser(null);
                    }
                } else {
                    logoutUser();
                    setUser(null);
                }
            }
        };

        initializeAuth();
    }, []);

    const getUserDataFromToken = (token) => {
        const decoded = jwt_decode(token);
        return {
            telegram_id: decoded.telegram_id
        };
    };

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};
