import axios from 'axios';
import { useContext } from 'react';
import { refreshToken, logoutUser, isTokenValid } from './services/authService';
import { AuthContext } from './context/AuthContext';
import { API_BASE_URL } from './config';

axios.defaults.baseURL = API_BASE_URL;

axios.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('access_token');
        if (token && isTokenValid(token)) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (
            error.response.status === 401 &&
            !originalRequest._retry &&
            !originalRequest.url.includes('/api/refresh')
        ) {
            originalRequest._retry = true;
            try {
                await refreshToken();
                originalRequest.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
                return axios(originalRequest);
            } catch (err) {
                const { setUser } = useContext(AuthContext);
                logoutUser(setUser);
                window.location.href = '/login';
                return Promise.reject(err);
            }
        }
        return Promise.reject(error);
    }
);
