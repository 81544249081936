import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './setupAxios';
import { Helmet } from "react-helmet";
import { AuthProvider } from './context/AuthContext';
import { TextProvider } from './context/TextContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <Helmet>
      {/* <script src="https://telegram.org/js/telegram-web-app.js" /> */}
    </Helmet>
    <TextProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </TextProvider>
  </React.StrictMode>
);
