import axios from "axios";
import { API_BASE_URL } from '../config';

export const searchPlaces = async (query) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/places?name=${query}`);
        return response.data;
    } catch (error) {
        console.error("Error place fetching:", error);
        throw error;
    }
};

export const goSerj = async (bookingInfo) => {
    try {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("access_token");
        const response = await axios.post(`${API_BASE_URL}/bookings`, bookingInfo, axios.defaults.headers.common['Authorization']);
        return response.data;
    } catch (error) {
        console.error("Ошибка при бронировании", error);
        throw error;
    }
};

export const getMyBookings = async () => {
    try {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("access_token");
        const response = await axios.get(`${API_BASE_URL}/bookings`, axios.defaults.headers.common['Authorization']);
        return response.data;
    } catch (error) {
        console.error("Ошибка при получении бронирования", error);
        throw error;
    }
};

