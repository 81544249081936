import React, { useState, useEffect, useContext } from "react";
import "./Hero.css";
import { heroImg, heroImgMobile, heroImgTablet } from "../../assets/images";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { searchPlaces, goSerj } from "../../services/serjServices"
import { TextContext } from "../../context/TextContext";
import { useHistory } from 'react-router-dom';

let SEARCH_MIN_CHAR = 1;

const getCurrentTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

const TimeInput = () => {
  return (
    <input
      type="time"
      defaultValue={getCurrentTime()}
      className="hero-from-time"
    />
  );
};

const Hero = () => {
  const { texts } = useContext(TextContext);
  const [chosenPlace, setChosenPlace] = useState("");
  const [place, setPlace] = useState("");
  const [placeId, setPlaceId] = useState(NaN);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [time, setTime] = useState(getCurrentTime());
  const [guests, setGuests] = useState(2);
  const navigate = useNavigate();
  const [placeResults, setPlaceResults] = useState([]); // Состояние для результатов поиска
  const [showDropdown, setShowDropdown] = useState(false); // Для управления видимостью выпадающего списка

  const fetchPlaceSuggestions = async (query) => {
    try {
      if (query)
      {
        const response = await searchPlaces(query);

        console.log(response)
        if (response)
          setPlaceResults(response);
        else
          setPlaceResults(query);
        setShowDropdown(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (place.length >= SEARCH_MIN_CHAR && chosenPlace != place) { // search if place len >= SEARCH_MIN_CHAR
      const delayDebounceFn = setTimeout(() => {
        fetchPlaceSuggestions(place);
      }, 500); // timeout 500ms

      return () => clearTimeout(delayDebounceFn); // clear timeout for every input data
    }
    else if (chosenPlace.name == place.name) 
    {}
    else {
      setPlaceResults([]);
      setShowDropdown(false);
    }
  }, [place, chosenPlace]);

  const handleSelectPlace = (selectedPlace, placeId) => {
    setPlace(selectedPlace);
    setChosenPlace(selectedPlace);
    setPlaceId(placeId);
    setShowDropdown(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (placeId && place && date && time && guests) {
      const serjForm = {
        "place_id": placeId, 
        "booking_date": date + 'T' + time,
        "num_of_people": guests,
      }
      try
      {
        const response = await goSerj(serjForm)
      }
      catch (error)
      {
  
      }
      // navigate('/process');
    } else {
      alert("Выберите хотя бы одно место");
    }
  };

  if (!texts) {
    return <div>...</div>;
  }

  return (
    
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      className="section-padding-x section-padding-y"
      id="hero"
    >
      <h1>
        {texts.home.title} <span>{texts.home.title_highlight}</span>
      </h1>

      <img src={heroImg} alt="hero" className="hero-bg-desktop" />
      <img src={heroImgTablet} alt="hero" className="hero-bg-tablet" />
      <img src={heroImgMobile} alt="hero" className="hero-bg-mobile" />
      <p>Подберем и забронируем лучшие рестораны легко и быстро</p>
      <form className="hero-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Место"
            className="hero-from-place"
            value={place}
            onChange={(e) => setPlace(e.target.value)}
          /> 

          {showDropdown && placeResults.length > 0 && (
          <ul className="place-dropdown">
            {placeResults.map((result) => (
              <li
                key={result.id}
                onClick={() => handleSelectPlace(result.name , result.id)} // Выбор места по клику
                className="place-dropdown-item"
              >
                {result.name}
              </li>
            ))}
          </ul>
        )}      
          
        <input
          type="date"
          defaultValue={date}
          className="hero-from-date"
          min={new Date().toISOString().split("T")[0]}
          onChange={(e) => setDate(e.target.value)}
        />
        
        <TimeInput time={time} setTime={setTime} />
        <input
          type="number"
          min="1"
          defaultValue={guests}
          placeholder="Гостей"
          className="hero-from-guests"
          onChange={(e) => setGuests(parseInt(e.target.value))}
        />
        <button type="submit" className="btn-purple">
          Засержить
        </button>
      </form>
    </section>
  );
};

export default Hero;