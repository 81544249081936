import React, { createContext, useState, useEffect } from 'react';
import { loadTexts } from '../utils/loadYaml';

export const TextContext = createContext();

export const TextProvider = ({ children }) => {
    const [texts, setTexts] = useState(null);
    // const [language, setLanguage] = useState('ru');  

    useEffect(() => {
        const fetchTexts = async () => {
            const data = await loadTexts();
            setTexts(data);
        };

        fetchTexts();
    }, []);

    return (
        <TextContext.Provider value={{ texts }}>
            {children}
        </TextContext.Provider>
    );
};
